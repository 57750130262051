<template>
  <div>
    <div class="columns">
      <div class="column">
        <input-base
          rules=""
          label="Descripción breve" 
          type="textarea"
          placeholder="Descripción breve"
          v-model="form.descripcion"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <tiny-editor
          rules=""
          label="Descripción larga" 
          placeholder="Descripción larga"
          v-model="form.descripcion_larga"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    value:{
      type: Object,
      default: () => {}
    }
  },
  computed:{
    form:{
      get(){
        return this.value || {}
      },
      set(e){
        this.$emit("input",e )
      }
    },
  },
};
</script>

