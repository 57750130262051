<template>
  <div>
    <title-bar
      :items="[
        { title: 'Negocios', to: `/form/${uri_form}` },
        { title: title_page },
      ]"
    >
    </title-bar>
    <div class="box">
      <form-observer ref="observer" @submit.prevent="onSubmit">
        <b-tabs type="is-boxed">
          <b-tab-item label="General">
            <form-general v-model="form"></form-general>
          </b-tab-item>
          <b-tab-item label="Descripciones">
            <form-otros v-model="form"></form-otros>
          </b-tab-item>
        </b-tabs>
        <div>
          <b-button
          native-type="submit"
          type="is-primary"
          icon-left="save"
          :loading="loading"
          class="mt-3 mr-3"
          >Guardar</b-button
        >
        <b-button
          type="is-secondary"
          icon-left="times"
          :loading="loading"
          class="mt-3"
          @click="handleCancel"
          >Cancelar</b-button
        >
        </div>
      </form-observer>
    </div>
  </div>
</template>

<script>
import FormGeneral from "../components/nuevo/FormGeneral";
import FormOtros from "../components/nuevo/FormOtros";
export default {
  components: {
    FormGeneral,
    FormOtros,
  },
  computed:{
    name_store(){
      return `form_${this.$route.meta.form}`
    },
    uri_form(){
      return this.$route.meta.form;
    },
    title_page(){
      const {id} = this.$route.query
      return id ? `Editar` : `Nuevo`
    }
  },
  data(){
    return {
      form: {},
      loading: false,
    }
  },
  async created(){
    const {id} = this.$route.query
    if(id){
      const data = await this.$store.dispatch(`${this.name_store}/get`, id)
      if(data){
        this.form = {...data}
      } else {
        this.$store.dispatch("app/set404", true)
      }
    } else {
      this.defaultData()
    }
  },
  methods:{
    defaultData(){
      this.form = {
        estado : 1
      }
      if(this.$refs.observer){
        this.$refs.observer.reset()
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      //
      this.loading = true;
      const row = await this.$store.dispatch(`${this.name_store}/save`, this.form)
      if(row){
        this.$store.dispatch(
          "app/setToastSuccess",
          `Se acaba de guardar el negocio: "${row.nombre_comercial}"`
        );
        if(!this.form.id) this.defaultData()
      }
      this.loading = false;
      
    },
    handleCancel(){
      if(Object.keys(this.form).length > 2){
        if(confirm("Desea cancelar el formulario?")){
          this.$router.go(-1)
        }
      } else {
        this.$router.go(-1)
      }
    }
  }
};
</script>